import BarStepper, { BarStepperItem } from 'components/BarStepper';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import TopBar from 'components/TopBar';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SignUpNotVerified from 'screens/SignUpNotVerified';
import { getNotVerified } from 'selectors/registrationSelectors';
import { getLoginState, getProfileIncomplete, getStoredProfile } from 'selectors/userSelectors';
import Optins from './Optins';
import Step1 from './Step1';
import Step2 from './Step2';

const SignUpScreen: FC = () => {
  const { t } = useTranslation();
  const [stepperIndex, setStepperIndex] = useState(0);
  const profile = useSelector(getStoredProfile);
  const loginState = useSelector(getLoginState);
  const isNotVerified = useSelector(getNotVerified);
  const { isRequiredMissing, isOptionalMissing, isOptinMissing } = getProfileIncomplete(profile);

  useEffect(() => {
    if ((!isOptinMissing && !isRequiredMissing && isOptionalMissing) || isNotVerified) {
      setStepperIndex(1);
    }
  }, [isRequiredMissing, isOptionalMissing, isNotVerified, isOptinMissing]);

  return isOptinMissing && !isRequiredMissing && !isOptionalMissing ? (
    <>
      <TopBar />
      <Panel>
        <Optins />
      </Panel>
    </>
  ) : (
    <>
      <TopBar
        title={!isOptinMissing ? t('Create a profile') : undefined}
        left={
          loginState !== 'LOGGED_IN' ? <MenuToggle toggleType="back" pushLocation="/" /> : undefined
        }
      />
      <Panel>
        <BarStepper startIndex={stepperIndex}>
          <BarStepperItem
            render={({ next }) =>
              isOptinMissing && !isRequiredMissing ? <Optins next={next} /> : <Step1 next={next} />
            }
          />
          <BarStepperItem
            render={() => (loginState === 'LOGGED_IN' ? <Step2 /> : <SignUpNotVerified isStep />)}
          />
        </BarStepper>
      </Panel>
    </>
  );
};

export default SignUpScreen;
