import { replace } from 'actions/historyActions';
import { resetPractice } from 'actions/practiceScheduleActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Coin from 'components/Coin';
import FlatButton from 'components/FlatButton';
import MenuToggle from 'components/MenuToggle';
import PanelFloatingCard from 'components/Panel/PanelFloatingCard';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import TopBar from 'components/TopBar';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPracticePoints } from 'selectors/practiceAnswerSelectors';
import { getLoginState } from 'selectors/userSelectors';

const PracticeGameEnded = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loginState = useSelector(getLoginState);
  const totalPoints = useSelector(getPracticePoints);

  return (
    <>
      <TopBar logo left={<MenuToggle />} />

      <Spacer spacing="small" />

      <PanelFloatingCard
        card={
          <Stack spacing="xsmall" align="center">
            <Text color="secondary" fontSize="xlarge">
              {t('Congratulations')}
            </Text>
            <Text color="black" fontSize="small">
              {t('You have total')}
            </Text>
            <Coin size="xlarge" value={totalPoints} />
            <Text color="black" fontSize="small">
              {t('points earned')}
            </Text>
          </Stack>
        }
      >
        {loginState !== 'LOGGED_IN' ? (
          <Box paddingX="gutter">
            <Stack spacing="xsmall" align="center">
              <Tooltip>
                <Stack spacing="small" align="center">
                  <Text color="secondary" fontSize="large">
                    {t('Caution')}
                  </Text>
                  <Text color="black" fontSize="small" textAlign="center">
                    {t(
                      'Do you want to be a studio candidate qualify and on Friday at Play the app jackpot?',
                    )}
                  </Text>
                  <Button
                    onClick={() => {
                      dispatch(replace('/profile'));
                      dispatch(resetPractice());
                    }}
                  >
                    {t('Login')}
                  </Button>
                </Stack>
              </Tooltip>

              <FlatButton to="/" onClick={() => dispatch(resetPractice())}>
                {t('Skip')}
              </FlatButton>
            </Stack>
          </Box>
        ) : (
          <Box paddingX="gutter">
            <Stack spacing="medium" align="center">
              <Text fontSize="small" color="black" textAlign="center">
                {t('Play with “Quizmaster” and secure the chance of the app jackpot every Friday')}
              </Text>
              <Text fontSize="large" color="secondary" textAlign="center">
                <Trans>
                  Monday to Friday,
                  <br />
                  7:35 p.m. on Servus TV
                </Trans>
              </Text>
              <Button to="/" onClick={() => dispatch(resetPractice())}>
                {t('Okay, leave')}
              </Button>
            </Stack>
          </Box>
        )}
      </PanelFloatingCard>
    </>
  );
};

export default PracticeGameEnded;
