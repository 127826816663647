import { getLoginState, getStoredProfile } from 'selectors/userSelectors';
import { AuthenticationResponse } from 'types/api/userApi';
import { Profile, ProfilePrivateProperties } from 'types/user';
import { isGuestUser } from 'utils/userHelpers';
import { ThunkAction } from './helpers';
import { updateVerifyAccount } from './interUserApiActions';
import { createProfile } from './talpaUserApiActions';
import {
  setSessionValidationState,
  storeUser,
  storeUserProfile,
  triggerLogout,
} from './userActions';

interface UpdateProfilePayload {
  displayName: string;
  avatarUrl: string;
  properties?: Record<string, string>;
  privateProperties?: Record<string, string>;
}

interface AddExternalIdCredentialsPayload {
  type: 'GOOGLE' | 'APPLE';
  externalId: string;
  displayName?: string;
}

/**
 * Continue with a guest account.
 */
export const registerAsGuest =
  (profile: Record<string, any> = {}): ThunkAction<Promise<void>> =>
  async (dispatch, _, { api }) => {
    try {
      const response = await api.post<any>('users/register/guest', {
        optionalValues: {
          privateProperties: {
            ...profile,
          },
        },
      });

      dispatch(storeUser(response));
    } catch {
      await dispatch(triggerLogout());
    }
  };

/**
 * Continue existing user session.
 */
export const loginByAuthenticationToken =
  (authenticationToken?: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    if (!authenticationToken) {
      await dispatch(triggerLogout());
      return;
    }

    dispatch(setSessionValidationState('VALIDATING'));

    try {
      const response = await api.post<AuthenticationResponse>(
        'users/usersessions/authenticationToken',
        {
          authenticationToken,
        },
      );

      const { loginCredentialStatus, displayName, profile, pttvSession } = response;

      const fromApple = /apple\.com/.test(document.referrer);

      if (fromApple && loginCredentialStatus.externalIds?.APPLE?.id) {
        updateVerifyAccount({
          email: loginCredentialStatus.email,
          getState,
          dispatch,
          response,
        });
      } else if (
        // If the response is a guest user but the guest user has a profile filled,
        // that was a mistake and the user should be logged out.
        isGuestUser(loginCredentialStatus, pttvSession) &&
        (!!displayName ||
          (profile.privateProperties &&
            Object.keys(profile.privateProperties).filter((key) => key !== 'countryCode').length >
              0))
      ) {
        throw 'Guest user has unexpected properties.';
      } else {
        dispatch(storeUser(response));
      }

      dispatch(setSessionValidationState('VALIDATED'));
    } catch {
      await dispatch(triggerLogout());
    }
  };

/**
 * Save user profile properties.
 */
export const updateProfile =
  (
    profile: ProfilePrivateProperties,
    isLoggedInOverride = false,
  ): ThunkAction<Promise<Profile | undefined>> =>
  async (dispatch, getState, { api }) => {
    try {
      const isLoggedIn = isLoggedInOverride || getLoginState(getState()) === 'LOGGED_IN';

      let updatedProfile: Profile = {
        privateProperties: profile,
      };

      if (!isLoggedIn) {
        dispatch(storeUserProfile(updatedProfile));
      } else {
        const storedProfile = getStoredProfile(getState());
        const displayName = [
          profile.firstName || storedProfile.firstName,
          profile.lastName || storedProfile.lastName,
        ]
          .filter((str) => !!str)
          .join(' ');
        const nonEmptyProfile = Object.entries(profile).reduce((reduced, [key, value]) => {
          if (!!value) {
            reduced[key as keyof ProfilePrivateProperties] = value;
          }

          return reduced;
        }, {} as Partial<ProfilePrivateProperties>);

        const response = await api.put<{ profile: Profile }, UpdateProfilePayload>(
          'users/profile',
          {
            displayName,
            avatarUrl: profile.avatarUrl || '',
            privateProperties: {
              ...nonEmptyProfile,
            },
          },
        );

        updatedProfile = response?.profile;

        if (updatedProfile.privateProperties) {
          dispatch(createProfile(updatedProfile.privateProperties));
        }
      }

      dispatch(storeUserProfile(updatedProfile));
      return updatedProfile;
    } catch {}

    return undefined;
  };

/**
 * Log out from the existing user session. Most likely you will want to call triggerLogout() instead.
 */
export const logout =
  (): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    try {
      await api.delete('users/usersessions/current');
    } catch {}
  };

/**
 * Save Google or Apple credentials to the user.
 */
export const addExternalIdCredentials =
  (payload: AddExternalIdCredentialsPayload): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    try {
      const response = await api.post<any>(`users/credentials/type/${payload.type}`, {
        externalId: payload.externalId,
        displayName: payload.displayName,
      });

      updateVerifyAccount({ getState, dispatch, response });
    } catch (error: any) {
      throw error;
    }
  };
