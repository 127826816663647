import { confirmAuthentication } from 'actions/interUserApiActions';
import {
  fetchStoredAuthenticationToken,
  setStoredAuthenticationToken,
} from 'actions/nativeActions';
import { setLoginState, setSessionValidationState } from 'actions/userActions';
import { loginByAuthenticationToken } from 'actions/usersApiActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMigrationStatus } from 'selectors/migrationSelectors';
import {
  getNativeSuspended,
  getParsedAuthenticationToken,
  getStoredAuthenticationToken,
  isNativeReady,
} from 'selectors/nativeSelectors';
import { getLoginState, getSessionValidationState } from 'selectors/userSelectors';

export const useCheckSession = () => {
  const dispatch = useDispatch();
  const sessionValidation = useSelector(getSessionValidationState);
  const storedAuthenticationToken = useSelector(getStoredAuthenticationToken);
  const token = useSelector(getParsedAuthenticationToken);
  const loginState = useSelector(getLoginState);
  const migrationStatus = useSelector(getMigrationStatus);
  const nativeSuspended = useSelector(getNativeSuspended);
  const nativeReady = useSelector(isNativeReady);

  useEffect(() => {
    if (
      !nativeReady ||
      nativeSuspended ||
      sessionValidation !== 'SHOULD_VALIDATE' ||
      loginState === 'LOGGING_OUT'
    ) {
      return;
    }

    dispatch(fetchStoredAuthenticationToken());
  }, [nativeSuspended, nativeReady, sessionValidation, loginState === 'LOGGING_OUT']);

  useEffect(() => {
    if (
      !token ||
      storedAuthenticationToken.status === 'NOT_FETCHED' ||
      storedAuthenticationToken.token === token ||
      loginState === 'LOGGING_OUT'
    ) {
      return;
    }

    dispatch(setStoredAuthenticationToken(token));
  }, [
    token,
    storedAuthenticationToken.status,
    storedAuthenticationToken.token,
    loginState === 'LOGGING_OUT',
  ]);

  useEffect(() => {
    if (migrationStatus !== 'FINISHED' || storedAuthenticationToken.status === 'NOT_FETCHED') {
      return;
    }

    const checkSession = async () => {
      if (!nativeSuspended && sessionValidation === 'SHOULD_VALIDATE') {
        const fromApple = /apple\.com/.test(document.referrer);
        const checkForAppleSession =
          fromApple && loginState !== 'LOGGED_IN' ? await dispatch(confirmAuthentication()) : false;

        if (!checkForAppleSession) {
          if (!!token) {
            dispatch(loginByAuthenticationToken(token));
          } else if (loginState !== 'LOGGING_OUT') {
            dispatch(setLoginState('LOGGED_OUT'));
            dispatch(setSessionValidationState('VALIDATED'));
          }
        }
      }
    };

    checkSession();
  }, [
    nativeSuspended,
    sessionValidation,
    token,
    loginState !== 'LOGGED_IN',
    loginState !== 'LOGGING_OUT',
    migrationStatus,
    storedAuthenticationToken.status,
  ]);
};
