import { push } from 'actions/historyActions';
import { waitForApple } from 'actions/socialActions';
import { createProfile } from 'actions/talpaUserApiActions';
import { setLoginState } from 'actions/userActions';
import { updateProfile } from 'actions/usersApiActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsGuestUser,
  getIsKnownUser,
  getLoginCredentialEmail,
  getLoginState,
  getProfileIncomplete,
  getStoredProfile,
} from 'selectors/userSelectors';

export const useLoginFlow = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getStoredProfile);
  const loginCredentialEmail = useSelector(getLoginCredentialEmail);
  const loginState = useSelector(getLoginState);
  const isKnownUser = useSelector(getIsKnownUser);
  const isGuestUser = useSelector(getIsGuestUser);

  useEffect(() => {
    if (!isKnownUser) {
      return;
    }

    window.setTimeout(() => {
      const { isRequiredMissing, isIncomplete } = getProfileIncomplete(profile);

      if (profile && !isRequiredMissing) {
        dispatch(createProfile(profile));
      }

      dispatch(push(isIncomplete ? '/sign-up' : '/'));
      dispatch(setLoginState('LOGGED_IN'));
      dispatch(waitForApple(false));
    }, 200);
  }, [isKnownUser]);

  useEffect(() => {
    if (
      loginState === 'LOGGED_IN' &&
      loginCredentialEmail &&
      profile.email &&
      loginCredentialEmail !== profile.email
    ) {
      dispatch(updateProfile({ email: loginCredentialEmail }));
    }
  }, [loginCredentialEmail, profile.email, loginState === 'LOGGED_IN']);

  useEffect(() => {
    if (loginState !== 'GUEST' && isGuestUser) {
      dispatch(setLoginState('GUEST'));
    }
  }, [loginState === 'UNKNOWN', isGuestUser]);
};
