import { Form } from '@blockle/form';
import { push } from 'actions/historyActions';
import { RegisterWithEmailData } from 'actions/interUserApiActions';
import { triggerLogout } from 'actions/userActions';
import { updateProfile } from 'actions/usersApiActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import FlatButton from 'components/FlatButton';
import { PolicyFields } from 'components/Registration';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRegistrationFields, getStoredProfile } from 'selectors/userSelectors';
import { getErrorCode } from 'utils/error';

type Dialog = {
  dialogTitle: string;
  dialogContent: string;
};

interface Props {
  next?: () => void;
}

const Optins = ({ next }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<Dialog | false>(false);

  const profile = useSelector(getStoredProfile);

  const onSubmit = async (formData: RegisterWithEmailData) => {
    try {
      await dispatch(updateProfile(getRegistrationFields({ ...formData, email: profile.email })));

      if (next) {
        next();
      } else {
        dispatch(push('/'));
      }
    } catch (error: any) {
      const code = getErrorCode(error);

      switch (code) {
        default: {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Something went wrong'),
          });
        }
      }
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ submitting }) => (
          <>
            <Box padding="gutter">
              <Stack spacing="large">
                <Stack spacing="small">
                  <Text fontSize="small" textAlign="center">
                    {t('Please agree to the revised policies and terms.')}
                  </Text>
                </Stack>
              </Stack>
            </Box>
            <Divider />
            <PolicyFields
              submitText={t('Submit')}
              submitting={submitting}
              values={{
                terms: profile.terms,
                participation: profile.participation,
                privacy: profile.privacy,
              }}
              hideValidFields
              hideDisclaimer
            />
            <Stack align="center" spacing="large">
              <FlatButton onClick={() => dispatch(triggerLogout())}>{t('Sign Out')}</FlatButton>
            </Stack>
          </>
        )}
      />
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={() => setDialog(false)}
        render={() => (
          <Box paddingY="gutter">
            <Stack align="center" spacing="large">
              <Stack align="center" spacing="medium">
                <Text color="secondary" fontSize="large" fontWeight="medium">
                  {dialog && dialog.dialogTitle}
                </Text>
                <Text color="black" fontSize="small">
                  {dialog && dialog.dialogContent}
                </Text>
              </Stack>
              <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};

export default Optins;
