export type RegionNames =
  | 'vienna'
  | 'tyrol'
  | 'lowerAustria'
  | 'upperAustria'
  | 'styria'
  | 'carinthia'
  | 'salzburg'
  | 'vorarlberg'
  | 'burgenland';

export type RegionNamesInGerman =
  | 'Vorarlberg'
  | 'Burgenland'
  | 'Steiermark'
  | 'Salzburg'
  | 'Tirol'
  | 'Wien'
  | 'Kärnten'
  | 'Oberösterreich'
  | 'Niederösterreich';

export type AgeGroups = '0TO12' | '13TO19' | '20TO39' | '40TO59' | '60PLUS';

export type Genders = 'MALE' | 'FEMALE';

export interface UserStatistics {
  [key: string]: UserStatisticsItem;
}

interface UserStatisticsItem {
  category: string;
  categoryImageUrl: string;
  percentage: number;
  points: number;
}

export interface Profile extends UserProfileProperties {
  userId?: string;
  displayName?: string;
  privateProperties?: ProfilePrivateProperties;
  participatedEpisodes?: ParticipatedEpisodes[];
}

export interface ProfilePrivateProperties extends UserProfileProperties {
  countryCode?: 'AT' | 'DE';
}

export const userProfileOptinProperties: Partial<keyof Profile>[] = [
  'terms',
  'privacy',
  'participation',
];

export const userProfileRequiredProperties: Partial<keyof Profile>[] = [
  'firstName',
  'gender',
  'location',
  'ageGroup',
];

export const userProfileOptionalProperties: Partial<keyof Profile>[] = ['lastName', 'phone'];

interface UserProfileProperties {
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
  gender?: Genders;
  location?: RegionNamesInGerman;
  ageGroup?: AgeGroups;
  email?: string;
  phone?: string;
  terms?: string;
  privacy?: string;
  participation?: string;
  externalId?: string;
  oldGigyaExternalId?: string;
  oldGigyaExternalIdAdded?: string;
  redBullMigrated?: string;
}

interface ParticipatedEpisodes {
  episodeCode: string;
  amountParticipated: number;
  amountCorrect: number;
  score: number;
}

export interface LoginCredentialStatus {
  email?: string;
  emailInvalidForAuthentication: boolean;
  userId: string;
  externalIds: Record<string, any>;
  authenticationToken: string;
}
