import { triggerLogout } from 'actions/userActions';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading/Heading';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import ShareButton from 'components/ShareButton/ShareButton';
import Stack from 'components/Stack';
import TopTierPlayer from 'components/Statistics/TopTierPlayer';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import InterstitialImage from 'public/images/interstitial.jpg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getPointsByEpisodeCode } from 'selectors/answersSelectors';
import { getEpisodeRankings, getEpisodeTopPlayer } from 'selectors/episodeSelectors';
import { getLoginState, getUserAvatar, getUserFirstName } from 'selectors/userSelectors';

interface Props {
  episodeCode: string;
}

const WaitingForLeaderboardFinished: FC<Props> = ({ episodeCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loginState = useSelector(getLoginState);
  const points = useSelector((state: AppState) => getPointsByEpisodeCode(state, episodeCode));
  const friendScores = true;
  const avatarUrl = useSelector(getUserAvatar);
  const firstName = useSelector(getUserFirstName);
  const top3 = useSelector(getEpisodeTopPlayer);
  const rankings = useSelector(getEpisodeRankings);
  const rank = rankings[points] || '-';

  return (
    <>
      <TopBar left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Box display="flex" flexDirection="column" flexGrow={1}>
        {friendScores ? (
          <Box paddingX="gutter" paddingTop="small">
            {loginState === 'LOGGED_IN' ? (
              <Stack spacing="large" align="center">
                <Text
                  component="h1"
                  color="secondary"
                  fontSize="xlarge"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {t('You made it to number {{rank}}', { rank })}
                </Text>
                {avatarUrl && <Avatar src={avatarUrl} size="large" />}
                <Stack spacing="small" align="center">
                  <Text fontSize="large" color="white" fontWeight="bold">
                    {firstName}
                  </Text>
                  <Text color="white" fontSize="medium">
                    {t('{{points}} points', { points })}
                  </Text>
                </Stack>
              </Stack>
            ) : (
              <Stack spacing="large" align="center" paddingX="medium">
                <Text
                  component="h1"
                  color="secondary"
                  fontSize="xlarge"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {t('Jetzt mit der Sendung mitspielen.')}
                </Text>
                <Text
                  component="h2"
                  color="white"
                  fontSize="medium"
                  textAlign="center"
                  fontWeight="medium"
                >
                  {t(
                    'Sie haben {{points}} Punkte bei Quizmaster erreicht. Erstellen Sie ein Profil, um zu sehen wie Sie im Vergleich zu anderen Spielern rangieren. Vollständig anmelden und jeden Freitag die Chance auf den App-Jackpot sichern!',
                    { points },
                  )}
                </Text>
                <Button
                  onClick={() => {
                    dispatch(triggerLogout());
                  }}
                >
                  {t('Profil Erstellen')}
                </Button>
              </Stack>
            )}
          </Box>
        ) : (
          <Stack spacing="xlarge" style={{ zIndex: -1 }}>
            <Heading
              title={t('Top Friends')}
              subTitle={t('None of your Facebook friends played Quizmaster today')}
            />

            <img
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              src={InterstitialImage}
            />
          </Stack>
        )}
      </Box>

      <Panel flexGrow={0}>
        <Stack spacing="large">
          {friendScores && (
            <Stack spacing="small">
              <Text component="h1" color="secondary" fontSize="xlarge" textAlign="center">
                {t("Today's Top Players")}
              </Text>
              <Box display="flex" justifyContent="center">
                <Stack spacing="large" horizontal align="center">
                  {top3.map((player) => (
                    <TopTierPlayer
                      key={player.userId}
                      avatarUrl={player.avatarUrl}
                      displayName={player.displayName}
                      rank={player.rank}
                      score={player.score}
                    />
                  ))}
                </Stack>
              </Box>
            </Stack>
          )}

          <Stack spacing="small" align="center">
            <ShareButton
              message={t('I got {{points}} points on Quizmaster. Can you do more? Click here!', {
                points,
              })}
            />
          </Stack>
        </Stack>
      </Panel>
    </>
  );
};

export default WaitingForLeaderboardFinished;
