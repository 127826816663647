import { LoginCredentialStatus } from 'types/user';

export const isKnownUser = (loginCredentialStatus?: LoginCredentialStatus) => {
  if (!loginCredentialStatus) {
    return false;
  }

  const { email, externalIds = {} } = loginCredentialStatus;
  const hasExternalIds = Object.keys(externalIds).length > 0;

  return !!email || hasExternalIds;
};

export const isGuestUser = (loginCredentialStatus?: LoginCredentialStatus, pttvSession?: string) =>
  !!pttvSession && !isKnownUser(loginCredentialStatus);
