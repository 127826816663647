/* eslint-disable @typescript-eslint/ban-ts-comment */
export class SoundLib {
  private context: AudioContext;
  private buffer = new Map<string, AudioBuffer>();

  constructor() {
    // touch/click event init audio context
    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    const context = (this.context = new AudioContext());

    const gain = context.createGain();
    gain.gain.value = 1;
    gain.connect(context.destination);

    const unlockListener = () => {
      this.playBuffer(context.createBuffer(1, 1, 22050));
      document.removeEventListener('touchend', unlockListener);
      document.removeEventListener('click', unlockListener);
    };

    document.addEventListener('touchend', unlockListener);
    document.addEventListener('click', unlockListener);
  }

  private playBuffer(buffer: AudioBuffer) {
    const source = this.context.createBufferSource();
    const gain = this.context.createGain
      ? this.context.createGain()
      : // @ts-ignore
        this.context.createGainNode();

    gain.gain.value = 1;

    source.buffer = buffer;
    source.connect(gain);
    gain.connect(this.context.destination);

    source.start(0);
  }

  public load(url: string) {
    // File already fetched
    if (this.buffer.has(url)) {
      return;
    }

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) =>
        this.context.decodeAudioData(buffer, (buffer) => this.buffer.set(url, buffer)),
      );
  }

  public play(url: string) {
    const buffer = this.buffer.get(url);

    if (!buffer) {
      return;
    }

    this.playBuffer(buffer);
  }
}
